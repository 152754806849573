import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
function QRCodeModal() {
  const [open, setOpen] = useState(false);

  // Style object for the modal content
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  // Function to handle opening the modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Show QR Code
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="qr-code-modal-title"
        aria-describedby="qr-code-modal-description"
      >
        <Box sx={style}>
          <Typography id="qr-code-modal-title" variant="h6" component="h2">
            Scan QR Code
          </Typography>
          <Typography id="qr-code-modal-description" sx={{ mt: 2 }}>
            Use your device to scan the QR code below.
          </Typography>
          <Box
            component="img"
            sx={{
              mt: 2,
              maxWidth: '100%',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src="https://firebasestorage.googleapis.com/v0/b/gottea-3daa4.appspot.com/o/Got%20Tea%20GK2%20Backup%20Payment%20QR.jpeg?alt=media&token=5042b4fb-43ff-48eb-bddb-de08284f4cb0" // Specify the path to your QR code image
            alt="QR Code"
          />
        </Box>
      </Modal>
    </div>
  );
}

export default QRCodeModal;
