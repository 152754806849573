import React from 'react';
import './StatsTable.css';
import Utilities from '../../../js/Utilities';
import Loader from '../Loader/Loader';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip } from '@mui/material';
import { List, ListItem, ListItemText, Paper, Chip, Typography, Box } from '@mui/material';
const moment = require('moment-timezone');


function getStatusChip(status){
    const statusColor = {
      created: 'default',
      authorized: 'primary',
      captured: 'success',
      refunded: 'info',
      failed: 'error'
    };

    return (
      <Chip
        style={{position:'absolute', top:'15px',right:1, fontWeight:'bold', fontSize:'12px'}}
        label={status.toUpperCase()}
        color={statusColor[status]}
        size="small"
      />
    );
  };


// function PaymentTable({ payments }) {
//     return (
//       <TableContainer component={Paper} style={{ maxWidth: 700, margin: 'auto' }}>
//         <Table aria-label="payment details table">
//           <TableHead>
//             <TableRow>
//               <TableCell align="right">Date</TableCell>
//               <TableCell>Payment ID</TableCell>
//               <TableCell align="right">UPI ID</TableCell>
//               <TableCell align="right">Amount</TableCell>
//               <TableCell align="right">Status</TableCell>

//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {payments.map((payment) => (
//               <TableRow key={payment.id}>
//                 <TableCell align="right">{new Date(payment.created_at * 1000).toLocaleString()}</TableCell>
//                 <TableCell component="th" scope="row">
//                   {payment.id}
//                 </TableCell>
//                 <TableCell align="right">{payment.vpa}</TableCell>
//                 <TableCell align="right">₹{payment.amount / 100}</TableCell>
//                 <TableCell align="right">{getStatusChip(payment.status)}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     );
//   }

function PaymentList({ payments }) {
    return (
        <Paper style={{ maxWidth: '90vw', margin: 'auto', padding: '1rem', width:'90%', backgroundColor:'#FCFBF4' }}>
            <List>
            
                {payments.map((payment) => (
                    <ListItem key={payment.id} divider>
                        <Box>
                        <p>Date: <b>{moment.tz(moment(new Date(payment.created_at * 1000)), 'Asia/Kolkata').format('Do MMMM (hh:mm A)')}</b></p>
                        <p>Payment ID: <b>₹{payment.id}</b></p>
                        <p>UPI ID: <b>{payment.vpa}</b></p>
                        </Box>
                        <Box>
                        {getStatusChip(payment.status)}
                        <p style={{position:'absolute', bottom:'3px',right:1, fontSize:'1.5em'}}><b>₹{payment.amount / 100}</b></p>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

class StatsTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
           showPieChart : false,
           sortMode: 'count'
        }
    }



render(){
    const { data, title, loading,skipFlavourBreakdown, showLargeAndSmall } = this.props;
    const { showPieChart, sortMode } = this.state;
 
    if(data.length <= 0){
        return null;
    }
    
    return <div className='statsTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3>{title}</h3>
            </div>            
            { data.length > 0 ? 
                <PaymentList payments={data}/> : <p>There is No Data</p>
            } 
        </React.Fragment> }
        </div>
}

}

export default StatsTable;