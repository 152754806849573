import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './Wastage.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusTag from '../../Common/StatusTag';
import OrderStats from '../../Common/OrderStats';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height:'5px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#91BCB9',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#008080',
    },
  }),
);

const Client = new Api();
const moment = require('moment');
const CURRENT_BRANCH = "GreaterKailash2";
const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  maxWidth:800,
  maxHeight:'80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY:'auto',
  p: 4,
};


class Wastage extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            processing:false,
            actionsLoading:false,
            materialsLoading:false,
            currentlyEditingOrder:{},
            editingOrder:false,
            actionItemsArr:[],
            orderItemsArr:[],
            orderItemsObj:{},
            processedItemsObj:{},
            editingMaterialName:'',
            editMode:false,
            activeMatId:'',
            selectedItem:{},
            selectedQuantity:0,
            fieldErrors:{},
            showOrderModal:false,
            confirmedOrder:false,
            deleteConfirmationShown:false,
            browser : Utilities.getCurrentBrowser(),
        }
    }

    loadActions = ()=>{
      let mainThis = this;
      this.setState({
          actionsLoading:true
      },()=>{
          let statusArr = ["Entry Done","Accepted","Recorded"];
          Client.searchActions('',{actionType:['wastageEntry'],branch:[CURRENT_BRANCH],status:statusArr}).then((loadedActions)=>{
              mainThis.setState({
                  actionItemsArr:loadedActions,
                  actionsLoading:false,
              });
          })
      })
  }

    loadMaterials = (callback)=>{
        this.setState({
            materialsLoading:true
        },()=>{
          Client.getActiveMaterials().then((data)=>{
                let oItems = [];        
                let catHash = {};        
                for(let item of data){
                  if(Utilities.isNullOrEmpty(catHash[item.category])){
                    catHash[item.category] = [];
                  }
                  catHash[item.category].push({ id:item.id, name : item.name, unitSize:parseFloat(item.unitSize), unit:item.unit,pricePerUnit:parseFloat(item.pricePerUnit)});
                }
                for(let c of categories){
                  oItems.push({ category : c, items : catHash[c]||[]});
                }
                callback(oItems);
            })
        })
    }

    goBack = ()=> {
      this.setState({
        confirmedOrder:false
      })
    }

    createEntry = ()=> {
      this.resetModal(()=>{
        this.setState({
          showOrderModal:true
        });
      });
    }

    editOrder = (order)=> {
      let orderItemsObj = _.cloneDeep(order.data.itemMap);
      let processedItemsObj = {};
      if(["Dispatched","Delivered","Delivered Partially"].includes(order.status)){
        processedItemsObj = _.cloneDeep(order.processedData.itemMap);
      }
      this.setState({
        orderItemsObj,
        processedItemsObj,
        currentlyEditingOrder:order,
        confirmedOrder:true,
        editingOrder:true,
        showOrderModal:true
      });
  }

    updateOrder = (statusToUpdate)=> {
      const {orderItemsObj,processedItemsObj,currentlyEditingOrder} = this.state;
      let action = {};
      action.actionType = "inventoryOrder";
      action.status = statusToUpdate || "Order Placed";
      let statusIsFine = true;
      if(action.status === "Delivered"){
        //Compare Objects
        for(let key in orderItemsObj){
          if(orderItemsObj[key].qty !== processedItemsObj[key].qty){
            statusIsFine = false;
            break;
          }
        }
        if(!statusIsFine){
          action.status = "Delivered Partially";
        }
      }

      action.data = {itemMap : _.cloneDeep(orderItemsObj),items:[]};
      let items = [];
      let orderItemsArrCopy = _.cloneDeep(this.state.orderItemsArr);
      for(let cat of orderItemsArrCopy){
        if(cat && cat.items.length > 0){
          let temp = [];
          let newObj = {};
          for(let item of cat.items){
            if(orderItemsObj[item.id]){
              temp.push(item);
            }
          }
          newObj = {category:cat.category, items: temp};
          items.push(newObj);
        }
      }
      action.data.items = items;
          this.setState({
            actionsLoading:true,
            showOrderModal:false,
          },()=>{
            Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
            this.loadActions();
            this.resetModal();
            });
          })
    }

    confirmOrder = ()=> {
      if(this.state.confirmedOrder){
        this.completeEntry();
      } else {
        this.setState({
          confirmedOrder:true
        });
      }
    }

    completeEntry = () => {
      const {orderItemsObj,orderItemsArr} = this.state;
      let currentThis = this;
      this.setState({
        processing:true
      },()=>{
        let action = {};
        action.actionType = "wastageEntry";
        action.branch = CURRENT_BRANCH;
        action.status = "Recorded";
        action.data = {itemMap : {...orderItemsObj},items:[]};
        let items = [];
        let orderItemsArrCopy = [...this.state.orderItemsArr];
        for(let cat of orderItemsArrCopy){
          if(cat && cat.items.length > 0){
            let temp = [];
            let newObj = {};
            for(let item of cat.items){
              if(orderItemsObj[item.id]){
                temp.push(item);
              }
            }
            newObj = {category:cat.category, items: temp};
            items.push(newObj);
          }
        }
        
        action.data.items = items;
        Client.createNewAction(action).then((res)=>{
          currentThis.setState({
            confirmedOrder:false,
            showOrderModal:false,
            processing:false
          },()=>{
            currentThis.loadActions();
          })
        });
      })
    }

    componentDidMount(){
        this.loadMaterials((oItems)=>{
          this.setState({
            materialsLoading:false,
            orderItemsArr:oItems
          },()=>{
            this.loadActions();
          })
        });
    }

    handleSearch = (e)=>{
        this.setState({
            searchTerm:e.target.value
        },()=>{
            this.debouncedMaterials();
        });
    }

    showEditModal = (selectedItem,materialName, materialId, qty)=>{
        this.setState({
          selectedItem,
          activeMatId:materialId,
          editingMaterialName:materialName,
          showOrderModal:true,
          selectedQuantity:qty,
          editMode:true
        });
    }

    showDeleteModal = (rowIndex,materialName, materialId)=>{
      this.setState({
        activeMatId:materialId,
        activeRowIndex:rowIndex,
        deleteConfirmationShown:true
      });
    }

    handleShowAddItem = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showOrderModal:true,
        });
      });
    }

    handleCloseModals = (e)=>{
      e.preventDefault();
      this.resetModal(()=>{
        this.setState({
          showOrderModal:false,
          deleteConfirmationShown:false
        });
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      if(fieldName === 'selectedItem'){
        let selectedItem = e;
        this.setState({
          selectedItem,
          fieldErrors:fieldErrorsCopy
        });
      } else {
        console.log("field errors state being set", fieldErrorsCopy);
        this.setState({
          [`${fieldName}`]:e.target.value,
          fieldErrors:fieldErrorsCopy
        },()=>{
  
        });
      }
    }

    handleAmountChange = (wastedItemId, e)=>{
      const {orderItemsObj} = this.state;
      let orderItemsObjCopy = _.cloneDeep(orderItemsObj);
      let val = e.target.value || 0;
      orderItemsObjCopy[wastedItemId] = {qty:parseFloat(val)}
      this.setState({
        orderItemsObj:orderItemsObjCopy
      },()=>{
      })
    }

    resetModal = (cb)=>{
      this.setState({
        orderItemsObj:{},
        confirmedOrder:false,
        activeMatId:undefined,
        activeRowIndex:undefined,
        editingOrder:false,
        editMode:false,
        fieldErrors:{},
        selectedItem:{},
        selectedQuantity:0
      },()=>{
        if(cb){
          cb();
        }
      });
    }

    addItemToOrder = (e)=>{
      const {selectedQuantity, selectedItem, orderItemsObj, orderItemsArr} = this.state;
      let orderItemsObjCopy = {...orderItemsObj};
      let orderItemsArrCopy = orderItemsArr.slice();
      if(this.validateFields()){
        if(this.state.editMode){
          orderItemsObjCopy[selectedItem.id] = {
            quantity: selectedQuantity,
            data: selectedItem
          }
        } else {
          orderItemsObjCopy[selectedItem.id] = {
            quantity: selectedQuantity,
            data: selectedItem
          }
          if(!this.state.orderItemsObj[selectedItem.id]){
            orderItemsArrCopy.push(selectedItem.id);
          }
        }
        
        this.setState({
          orderItemsArr:orderItemsArrCopy,
          orderItemsObj:orderItemsObjCopy,
          showOrderModal:false
        })
      }
    }


    validateFields = ()=>{
      let errors = {};
      if(Utilities.isNullOrEmpty(this.state.selectedItem)){
        errors['selectedItem'] = 'Item is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.selectedQuantity) || this.state.selectedQuantity <= 0){
        errors['selectedQuantity'] = 'Quantity cannot be zero.';
      }
      if(!(Utilities.isNullOrEmpty(errors))){
        console.log(errors);
        this.setState({
          fieldErrors:errors
        });
        return false;
      }
      return true;
    }

    renderActionsTable(){
      const {actionItemsArr } = this.state;
      return <TableContainer sx={{maxHeight:'60vh',padding:0}} component={Paper}>
        <Table sx={{ minWidth: 650,padding:0 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Details</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{padding:0}}>
            {this.state.actionsLoading ? <TableRow
                key={'loader'}
              >
                <TableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                <Loader/>
                </TableCell>
                </TableRow> : <>{actionItemsArr.map((row,index) => (
              <TableRow
                key={`${row}-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                <b>Order ID</b>: {row.id}<br/>
                <b>Date</b>: {moment(row.createdAt).format("ddd DD-MMM-YYYY, hh:mm A")}
                </Typography>
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                <StatusTag status={row.status}/>
                </Typography>
                </TableCell>
                <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Button color="success" variant="contained" children={<EyeIcon />} onClick={()=>this.editOrder(row)}/>
                </Stack>
                  </TableCell>
              </TableRow>
            ))}</>}
            
          </TableBody>
        </Table>
      </TableContainer>;
    }
    
    
    render(){
        const {orderItemsObj,processedItemsObj, orderItemsArr} = this.state;

        return  <div className='wastageContainer'><ReportsHeader/>
        <Box sx={{padding:0}}>
        <Modal
          open={this.state.deleteConfirmationShown}
          onClose={this.handleCloseModals}
          aria-labelledby="modal-modal-title" 
          aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
          <Typography sx={{mb:5, textAlign:'center'}}>Are you sure you want to delete <b>{this.state.activeMaterialName}</b>?</Typography>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
                  <Button 
                  color="success" 
                  variant="contained" 
                  startIcon={<EditIcon/>}
                  onClick={()=>this.deleteItem(this.state.activeRowIndex)}
                  >Yes, Delete</Button>
                  <Button 
                  color="error" 
                  variant="contained" 
                  startIcon={<DeleteIcon/>}
                  onClick={this.handleCloseModals}
                  >No, Don't</Button>
          </Stack>
        </Box>
        </Modal>
        <Modal
        open={this.state.showOrderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
        <Box>
        {this.state.editingOrder ? <OrderStats orderData={this.state.currentlyEditingOrder}/> : ''}
        {this.state.confirmedOrder ? <TableContainer sx={{maxHeight:'60vh'}} component={Paper}>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
           {orderItemsArr.map((cat,indexCat) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow key={`row-${cat}-${indexCat}`}><TableCell>
                      <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{cat.category}</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Wasted</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
                <Typography variant="subtitle1" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty)} ${row.unit}` : ''}
                </Typography>
                </Stack>
              </TableCell>
                {(this.state.confirmedOrder && ["Dispatched"].includes(this.state.currentlyEditingOrder.status)) ?
                <TableCell align="left">
                  <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                  <TextField 
                  label="Wasted Amount" 
                  type="number" 
                  variant="outlined"  
                  value={orderItemsObj[row.id] ? orderItemsObj[row.id].qty : ""}
                  onChange={(e)=>{this.handleAmountChange(row.id,e)}}
                  // error={this.state.fieldErrors['wastedAmount'] ? true :false} 
                  // helperText={this.state.fieldErrors['wastedAmount']} 
                  />
                  <Typography variant="subtitle1" sx={{display:'flex',alignItems:'center',minWidth:50,textAlign:'center'}}>
                  {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
                  </Typography>
                  </Stack>
                </TableCell> : ''}
              </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : '';}
          )}
          </TableBody></Table>
          </TableContainer> : orderItemsArr.map((cat,catIndex) => { 
          return <Accordion key={`accord-${cat}-${catIndex}`}>
          <AccordionSummary
          sx={{background:'#F3F1E4'}}
          expandIcon={<ExpandMoreIcon />}
          >
          <Typography variant="subtitle1" component="h6" sx={{fontWeight:"bold",color:"#47908a",textAlign:'left'}}>{cat.category}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{padding:0}}>
          <TableContainer sx={{maxHeight:'60vh',width:'auto'}} component={Paper}>
          <Table aria-label="simple table" stickyHeader>
          <TableHead>
          <TableRow>
          <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Name</TableCell>
          <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Wasted</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {this.state.materialsLoading ? <TableRow
          key={'loader'}
          >
          <TableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
          <Loader/>
          </TableCell>
          </TableRow> : <>{cat.items.map((row,index) => (
          <TableRow
          key={`${row}-${index}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell align="left" component="th" scope="row" sx={{width:'38vw'}}>
            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
          {row.name}
          </Typography>
          </TableCell>
          <TableCell align="left">
          <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                  <TextField
                  sx={{minWidth:'200px'}} 
                  label="Wasted Amount" 
                  type="number" 
                  variant="outlined"  
                  value={orderItemsObj[row.id] ? orderItemsObj[row.id].qty : ""}
                  onChange={(e)=>{this.handleAmountChange(row.id,e)}}
                  // error={this.state.fieldErrors['wastedAmount'] ? true :false} 
                  // helperText={this.state.fieldErrors['wastedAmount']} 
                  />
                  <Typography variant="subtitle1" sx={{display:'flex',alignItems:'center',minWidth:50,textAlign:'center'}}>
                  {(orderItemsObj[row.id] && !Utilities.isNullOrEmpty(orderItemsObj[row.id].qty)) ? `${parseFloat(orderItemsObj[row.id].qty)} ${row.unit}` : ''}
                  </Typography>
          </Stack>
          </TableCell>
          </TableRow>
          ))}</>}
          </TableBody>
          </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>;}
        )}
        
      <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%' justifyContent="flex-end" sx={{mt:2}}>
      {(this.state.confirmedOrder && ((this.state.editingOrder && this.state.currentlyEditingOrder.status === "Order Placed") || !this.state.editingOrder)) ? <Button color="success" variant="contained" onClick={this.goBack} disabled={this.state.processing}>
        {'Edit'}
      </Button> : ''}
      {(this.state.editingOrder && this.state.confirmedOrder) ? 
      <>{this.state.currentlyEditingOrder.status === "Order Placed" ? <Button color="success" variant="contained" onClick={this.updateOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Update Order'}
    </Button> : ''}</> : 
      <Button color="success" variant="contained" onClick={this.confirmOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj) || this.state.processing}>
        {this.state.confirmedOrder ? 'Complete Entry' : 'Confirm Entry'}
      </Button>}
      {(this.state.confirmedOrder && ["Dispatched"].includes(this.state.currentlyEditingOrder.status)) ? <Button color="success" variant="contained" onClick={()=>this.updateOrder("Delivered")} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
        Mark as Received
      </Button> : ''}
      <Button color="error" variant="contained" onClick={this.handleCloseModals} disabled={this.state.processing}>
        {'Close'}
      </Button>
      </Stack>
      </Box>
        </Box>
        </Modal>
        <Button color="success" variant="contained" sx={{mb:2}} onClick={this.createEntry}>
          New Entry
        </Button>
      {this.renderActionsTable()}
      </Box>
      </div>
    } 
}

export default Wastage;